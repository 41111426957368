import React, { useState, useEffect } from 'react'

import { useSelect } from 'store/index'

import style from './Property-Carousel.module.scss'
import Arrow from './Arrow'

import Property from '../Properties/Property'

type PropertyCarouselProps = {
  data: any[]
  id?: string
  styleClass?: string
}

const PropertyCarousel: React.FC<PropertyCarouselProps> = ({
  data,
  id,
  styleClass,
}) => {
  // set basic internal state
  const [slidesToShow] = useState(3)
  const [currentPosition, setCurrentPosition] = useState(0)
  const [distance, setDistance] = useState(375)

  // window dimensions from custom hook
  const width = useSelect((state) => state.uiState.width)

  useEffect(() => {
    // set distance the slides movePosition
    // based on window width
    if (width > 1272) {
      setDistance(367)
    } else if (width <= 1272 && width > 1225) {
      setDistance(350)
    } else if (width <= 1225 && width >= 1024) {
      setDistance(305)
    } else {
      setDistance(0)
      setCurrentPosition(0)
    }
  }, [currentPosition, width])

  const setCarousel = () => {
    if (data) {
      return data.map(({ label, image, description, price, link }, i) => (
        <Property
          description={description}
          img={image}
          key={i}
          label={label}
          link={link ? `${link}${link.split('?')[1] ? '&' : '?'}if=hpc1` : ''}
          //width={width > 1272 ? 343 : width > 1205 ? 325 : 285}
        />
      ))
    }
    return null
  }

  const movePosition = (pos) => {
    if (pos === 'neg') {
      if (currentPosition !== 0) {
        setCurrentPosition(currentPosition - slidesToShow)
      }
    } else {
      if (currentPosition !== data.length - slidesToShow) {
        setCurrentPosition(currentPosition + slidesToShow)
      }
    }
  }

  return (
    <div
      className={`${style.propertyCarousel} ${'carousel'} ${
        styleClass ? style[styleClass] : ''
      }`}
    >
      {currentPosition > 0 && (
        <Arrow
          className={` ${style.propertyCarouselArrow}`}
          direction="left"
          id={id}
          onClick={() => movePosition('neg')}
        />
      )}
      <div className="carousel-track" style={{ height: 270 }}>
        <div
          className="slider-wrapper"
          style={{
            transform: `translateX(-${currentPosition * distance}px)`,
          }}
        >
          {setCarousel()}
        </div>
      </div>
      {data && currentPosition !== data.length - slidesToShow && (
        <Arrow
          className={` ${style.propertyCarouselArrow} ${style.propertyCarouselArrowRight}`}
          direction="right"
          id={id}
          onClick={() => movePosition('pos')}
        />
      )}
    </div>
  )
}

export default PropertyCarousel
