import React from 'react'

// Icons
import Left from 'assets/icons/icon-arrowLeft.svg'
import LeftWhite from 'assets/icons/icon-arrowLeftWhite.svg'
import Right from 'assets/icons/icon-arrowRight.svg'
import RightWhite from 'assets/icons/icon-arrowRightWhite.svg'

export type ArrowProps = {
  direction: string
  className: string
  onClick?: () => void
  dark?: boolean
  id?: string
  ariaLabel?: string
}

const Arrow: React.FC<ArrowProps> = ({
  ariaLabel,
  direction,
  className,
  onClick,
  dark,
  id,
}) => {
  return (
    <button
      aria-label={ariaLabel}
      className={`carouselArrow arrow${direction} ${className}`}
      id={id && `${id}_btn_${direction}`}
      onClick={onClick}
    >
      {direction === 'left' ? (
        dark ? (
          <LeftWhite height={40} width={40} />
        ) : (
          <Left height={40} width={40} />
        )
      ) : dark ? (
        <RightWhite height={40} width={40} />
      ) : (
        <Right height={40} width={40} />
      )}
    </button>
  )
}

export default Arrow
