import React, { useCallback, useEffect, useState } from 'react'
import Image from 'next/image'

import { useSelect } from 'store/index'

import CustomLink from 'components/Link/CustomLink'

import Arrow from './Arrow'
import style from './RecommendedDestinationsCarousel.module.scss'

import { pushToDataLayer } from 'utils/Gtm'
import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'

// Types
type Destination = {
  image: { url: string; alt?: string }
  link: string
  name: string
  destination?: string
}
type DestinationData = {
  label: string
  title: string
  subtitle: string
  destinations: Destination[]
}
type RecommendedDestinationsCarouselProps = {
  id: string
  useGradient?: boolean
  customDestinations?: DestinationData
  recommendedDestinations?: DestinationData
  styleClass?: string
}

const RecommendedDestinationsCarousel: React.FC<
  RecommendedDestinationsCarouselProps
> = ({
  id,
  useGradient,
  customDestinations,
  recommendedDestinations,
  styleClass,
}) => {
  const width = useSelect((state) => state.uiState.width)
  const [slidesToShow, setSlidesToShow] = useState<number>(5)
  const [currentPosition, setCurrentPosition] = useState<number>(0)

  const destinationData = customDestinations
    ? customDestinations
    : recommendedDestinations

  useEffect(() => {
    if (width < 1260 && width > 1023) {
      setSlidesToShow(4)
    }
  }, [width])

  const handleDirectionChange = (goForward: boolean) => {
    if (goForward) {
      if (
        destinationData?.destinations?.length &&
        currentPosition !== destinationData.destinations.length - slidesToShow
      ) {
        setCurrentPosition(currentPosition + slidesToShow)
      }
    } else {
      if (currentPosition !== 0) {
        setCurrentPosition(currentPosition - slidesToShow)
      }
    }
  }

  const handleClick = useCallback((destination?: string) => {
    destination &&
      pushToDataLayer('bucketListClick', {
        bucketListClickLLP: destination,
      })
  }, [])

  const renderSlides = () => {
    if (destinationData?.destinations) {
      return destinationData.destinations.map((destination) => {
        let destinationLink = customDestinations
          ? `/vacation-rentals/${destination.link}`
          : destination.link
        destinationLink = `${destinationLink}${
          destinationLink.split('?')[1] ? '&' : '?'
        }if=hpc2`

        const altText = destination.image.alt
          ? destination.image.alt
          : destination.destination

        return (
          <CustomLink
            className={`${style.destinationItem} btn-cta`}
            href={destinationLink}
            key={
              customDestinations ? destination.name : destination.destination
            }
            onClick={() =>
              handleClick(
                customDestinations ? destination.name : destination.destination,
              )
            }
            prefetch={false}
          >
            <Image
              alt={altText || 'Destination Image'}
              height={240}
              loader={cloudinaryLoader}
              src={getWordpressCloudinaryId(destination.image.url)!}
              width={204}
            />

            <div className={style.destinationItemMask}></div>
            <span className={style.destinationLocation}>
              {customDestinations ? destination.name : destination.destination}
            </span>
          </CustomLink>
        )
      })
    }

    return null
  }

  return destinationData ? (
    <section
      className={`${style.destinationSection} section ${
        useGradient ? style.hasGradient : ''
      }
        ${styleClass ? style[styleClass] : ''}`}
      id={id}
    >
      <div className={style.content}>
        <div className={style.destinationTitle}>
          <span className="section-label-sm blue">{destinationData.label}</span>
          <h2 className="section-title-sm">{destinationData.title}</h2>
          <span
            className={`${'section-subtitle-sm'} ${style.destinationsSubtitle}`}
          >
            {destinationData.subtitle}
          </span>
        </div>
        <div className={`${style.destinationCarousel} carousel`}>
          {currentPosition !== 0 && (
            <Arrow
              className={`${style.carouselArrow} arrowleft`}
              direction="left"
              id={`${id}-left-arrow`}
              onClick={() => handleDirectionChange(false)}
            />
          )}
          <div className={`${style.destinationCarouselTrack} carousel-track`}>
            <div
              className="slider-wrapper"
              style={{
                transform: `translateX(-${currentPosition * 219}px)`,
              }}
            >
              {renderSlides()}
            </div>
          </div>
          {currentPosition !==
            destinationData.destinations.length - slidesToShow &&
            destinationData.destinations.length > slidesToShow &&
            currentPosition + slidesToShow <
              destinationData.destinations.length && (
              <Arrow
                className={`${style.carouselArrow} ${style.destinationRightArrow}`}
                direction="right"
                id={`${id}-right-arrow`}
                onClick={() => handleDirectionChange(true)}
              />
            )}
        </div>
      </div>
    </section>
  ) : null
}

export default RecommendedDestinationsCarousel
