import type { ImageLoader } from 'next/image'

const cloudinaryLoader: ImageLoader = ({ src, width, quality }) => {
  /*
    The Cloudinary plugin in the CMS has been updated and with it the URL pattern has changed.
    Since the Algolia URLs did not change their format, it is necessary to differentiate
    from which source the loader is loading images.

    CMS URL: https://res.cloudinary.com/evolve-vacation-rental-network/images/f_auto/v1629737244/evolvevacationrental.com/BytheNumbers_BeachHouses_SurfsideED/BytheNumbers_BeachHouses_SurfsideED.jpg?_i=AA
    Algolia URL:   https://res.cloudinary.com/evolve-vacation-rental-network/image/upload/f_auto,c_limit,w_256,q_auto/listings_s3/listings/293144/images/main/a0F6000000I80NVEAZ
  */
  const path = /evolvevacationrental.com/.test(src) ? 'images' : 'image/upload'
  return `https://res.cloudinary.com/evolve-vacation-rental-network/${path}/f_auto,c_limit,w_${width},q_${
    quality || 'auto'
  }/${src}`
}

export default cloudinaryLoader
