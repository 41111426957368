import React from 'react'
import Image from 'next/image'

import CustomLink from 'components/Link/CustomLink'

import style from './Property.module.scss'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'

const Property = ({ label, img, description, link }) => {
  // preserve UTM params in link
  return (
    <div className={style.property}>
      <CustomLink className="btn-cta" href={link || ''} prefetch={false}>
        <div className={`${style.image} image`}>
          <Image
            alt={img.alt ? img.alt : description}
            fill
            loader={cloudinaryLoader}
            sizes="(max-width: 1205px) 285px, (max-width: 1272px) 325px, 343px"
            src={getWordpressCloudinaryId(img.url)!}
            style={{
              objectFit: 'cover',
            }}
          />
        </div>

        <div className={description}>
          <div>
            <label className={style.propertyLabel}>{label}</label>
          </div>
          <div className={style.geoLocation}>{description}</div>
        </div>
      </CustomLink>
    </div>
  )
}

export default Property
